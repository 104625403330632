<template>
  <div class="cmtnest-reply my-75">
    <b-link class="nav-link reply-count" @click="getReplyComments(itemId)">
      <feather-icon v-if="!show" icon="PlusIcon" size="12" />
      <feather-icon v-if="show" icon="MinusIcon" size="12" />
      {{ reply_count }} Replies
    </b-link>
    <div class="nest-reply my-75" v-if="show"
        v-for="(comment, index) in replies"
        :key="index">
      <div 
        :class="comment.user.hashid == userData.hashid ? 'nested-main cmd-owner' : 'nested-main'"
      >
      <div class="d-flex align-items-start w-100 over-cmd">
      <b-avatar :src="comment.user.avatar" size="30" class="mr-75" />
        <div class="mt-05  w-100" v-if="!editComments.includes(comment.hashid)">
          <div class="profile-user-info w-100 right-cmt">
            <div class="d-flex align-items-start justify-content-between">
              <div class="profile-user-info">
                <h6 class="mb-0">
                  {{ comment.user.name }}
                </h6>
                <small style="font-size: 12px">
                  {{ comment.user.designation }}
                </small>
              </div>
              <div class="d-flex flex-column flex-lg-row align-items-lg-center">
                <span class="small mr-lg-50">
                  {{ comment.created_at }}
                </span>
                <div class="d-block">
                  <!-- More Options Dots -->
                      <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0" no-caret  v-if="comment.user.hashid == userData.hashid">
                        <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted" v-b-tooltip.hover.v-default title="More Options"/>
                        </template>
                            <b-dropdown-item
                              class="btn-sm p-0 text-muted"
                              v-if="comment.user.hashid == userData.hashid"
                      @click="editComments.pop(), editComments.push(comment.hashid)"
                            >
                                <feather-icon icon="EditIcon" size="18" 
                                v-b-tooltip.hover.v-default class="text-primary mr-50"
                                title="Comment Edit"/><span>Edit</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="comment.user.hashid == userData.hashid" class="btn-sm p-0 text-danger" @click="deleteComment(comment.hashid, comment.post_hashid, itemId)">
                            <feather-icon icon="TrashIcon" size="18" 
                          v-b-tooltip.hover.v-danger class="text-primary mr-50"
                          title="Delete Comment"/>
                              <span class="">Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                      <!-- End More Options Dots -->
                    <!-- <b-link
                        class="text-body text-body btn btn-icon rounded-circle  p-50"
                        v-b-tooltip.html.hover="'Liked by ' + getNames(comment.likes)"
                        @click="likeComment(comment, itemId)"
                    >
                        <feather-icon
                            icon="HeartIcon"
                            size="18"
                            :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                        />
                        <span
                            v-if="comment.likes.length"
                            class="text-muted align-middle"
                        >
                    {{ comment.likes.length }}
                  </span>
                    </b-link>
                    <b-link
                        v-if="comment.user.hashid == userData.hashid"
                        class="text-primary btn btn-icon rounded-circle  p-50"
                        @click="editComments.pop(), editComments.push(comment.hashid)"
                    >
                        <feather-icon icon="EditIcon" size="18" />
                    </b-link>
                    <b-link
                        v-if="comment.user.hashid == userData.hashid"
                        class="text-danger btn btn-icon rounded-circle p-50"
                        @click="
                    deleteComment(comment.hashid, comment.post_hashid, itemId)
                  "
                    >
                        <feather-icon icon="TrashIcon" size="18" class="mr-lg-25" />
                    </b-link> -->
                </div>
              </div>
            </div>
            <div class="mt-75 blog-description"  v-html="comment.comment"></div>
            <!-- Mobile Code -->
              <!-- <div class="d-flex d-lg-none">
                  <b-link
                      class="text-body text-body btn btn-icon rounded-circle pl-0  pt-50 pb-50 pr-50"
                      v-b-tooltip.html.hover="'Liked by ' + getNames(comment.likes)"
                      @click="likeComment(comment, itemId)"
                  >
                      <feather-icon
                          icon="HeartIcon"
                          size="18"
                          :class="comment.youLiked ? 'profile-likes' : 'profile-icon'"
                      />
                      <span
                          v-if="comment.likes.length"
                          class="text-muted align-middle"
                      >
                    {{ comment.likes.length }}
                  </span>
                  </b-link>
                  <b-link
                      v-if="comment.user.hashid == userData.hashid"
                      class="text-primary btn btn-icon rounded-circle  p-50"
                      @click="editComments.pop(), editComments.push(comment.hashid)"
                  >
                      <feather-icon icon="EditIcon" size="18" />
                  </b-link>
                  <b-link
                      v-if="comment.user.hashid == userData.hashid"
                      class="text-danger btn btn-icon rounded-circle p-50"
                      @click="
                    deleteComment(comment.hashid, comment.post_hashid, itemId)
                  "
                  >
                      <feather-icon icon="TrashIcon" size="18" class="mr-lg-25" />
                  </b-link>
              </div> -->
            <!-- <div>
            <b-link class="nav-link">
              <feather-icon icon="CornerUpLeftIcon" size="18" class="mr-25" />
              Reply
            </b-link>
          </div> -->
          <!-- End Mobile Code -->
          </div>
        </div>
        <div class="w-100 cmt-posts d-flex align-items-end justify-content-centent" 
        v-else="editComments.includes(comment.hashid)">
        <div
          class="
            border
            p-751
            d-flex
            flex-row
            align-items-end
            justify-content-around
            commentBox
            round
            w-100
            mt-05
          "
        >
          <CommentBox
            placeholder="Share your thoughts..."
            className="w-100 comment-box edit-cmt"
            :id="'comment-field-' + comment.hashid"
            :value="comment.comment"
            :toolbar="false"
            :mentionList="mentionList"
          />
        </div>
        <b-button
              variant="primary"
              @click="editComment(comment, itemId)"
              pill
              class="blog-btn-block ml-50 search-post w-auto mb-0"
              :disabled="commentSubmitClicked"
            >
            <strong><b>Save</b></strong>
            </b-button>
            <b-link
          v-if="editComments.includes(comment.hashid)"
          class="btn btn-icon rounded-circle btn-outline-danger ml-75 p-50 mt-50"
          @click="editComments.pop()"
        >
          <feather-icon icon="XIcon" size="18" />
        </b-link>
        </div>
      </div>
      <div class="option-reply d-flex align-items-center justify-content-between w-100 flex-row-reverse pl-3">
        <b-row class="like-button mx-0 pr-1">
                  <div class="action-group d-flex align-items-center my-25">
                    <b-link
                    class="d-flex align-items-center text-muted text-nowrap">
                  <div class="likes" 
                  v-if="!comment.youLiked">
                    <!-- <feather-icon
                      icon="HeartIcon"
                      class="mr-50 text-primary profile-icon"
                      size="18"
                      title="Like"
                      @click="likeComment(comment, index,'like')"
                    /> -->
                    <!-- <b-img
                        :src="
                          require('@/assets/images/icons/like/beforeLike.png')
                        "
                        width="18"
                        title="Like"
                        class="mr-50 active-like"
                        @click="likeComment(comment, index,'like')"
                      ></b-img> -->
                      <span  title="Like"
                      class="active-like"
                      @click="likeComment(comment, index,'like')">Like </span>
                      <div class="emoji-reactions">
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'like')"><b-img :src="require('@/assets/images/icons/like/like.svg')" width="18" v-b-tooltip.hover.v-default title="Like"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'clap')"><b-img :src="require('@/assets/images/icons/like/clapping.svg')" width="18" v-b-tooltip.hover.v-default title="Celebrate"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'love')"><b-img :src="require('@/assets/images/icons/like/love-icon.svg')" width="18" v-b-tooltip.hover.v-default title="Love"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'support')"><b-img :src="require('@/assets/images/icons/like/hand-heart.svg')" width="18" v-b-tooltip.hover.v-default title="Support"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'idea')"><b-img :src="require('@/assets/images/icons/like/lightbulb.svg')" width="18" v-b-tooltip.hover.v-default title="Insightful"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'smile')"><b-img :src="require('@/assets/images/icons/like/smile.svg')" width="18" v-b-tooltip.hover.v-default title="Funny"></b-img></div>
                      </div>
                    </div>
                    <div class="likes" 
                      v-if="comment.youLiked && comment.likeType">
                    
                    <div v-if="comment.likeType == 'like'" @click="likeComment(comment, index,'like')"><b-img :src="require('@/assets/images/icons/like/like.svg')" width="20" title="Like" class="active-like "></b-img></div>
                    <div v-if="comment.likeType == 'clap'" @click="likeComment(comment, index,'clap')"><b-img :src="require('@/assets/images/icons/like/clapping.svg')" width="20" title="Celebrate"></b-img></div>
                    <div v-if="comment.likeType == 'love'" @click="likeComment(comment, index,'love')"><b-img :src="require('@/assets/images/icons/like/love-icon.svg')" width="20" title="Love"></b-img></div>
                    <div v-if="comment.likeType == 'support'" @click="likeComment(comment, index,'support')"><b-img :src="require('@/assets/images/icons/like/hand-heart.svg')" width="20" title="Support"></b-img></div>
                    <div v-if="comment.likeType == 'idea'" @click="likeComment(comment, index,'idea')"><b-img :src="require('@/assets/images/icons/like/lightbulb.svg')" width="20" title="Insightful" class="active-idea"></b-img></div>
                    <div v-if="comment.likeType == 'smile'" @click="likeComment(comment, index,'smile')"><b-img :src="require('@/assets/images/icons/like/smile.svg')" width="20"  title="Funny"></b-img></div>

                      <div class="emoji-reactions">
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'like')"><b-img :src="require('@/assets/images/icons/like/like.svg')" width="22" v-b-tooltip.hover.v-default title="Like"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'clap')"><b-img :src="require('@/assets/images/icons/like/clapping.svg')" width="22" v-b-tooltip.hover.v-default title="Celebrate"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'love')"><b-img :src="require('@/assets/images/icons/like/love-icon.svg')" width="22" v-b-tooltip.hover.v-default title="Love"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'support')"><b-img :src="require('@/assets/images/icons/like/hand-heart.svg')" width="22" v-b-tooltip.hover.v-default title="Support"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'idea')"><b-img :src="require('@/assets/images/icons/like/lightbulb.svg')" width="22" v-b-tooltip.hover.v-default title="Insightful"></b-img></div>
                        <div class="emoji-item py-0" @click="likeComment(comment, index,'smile')"><b-img :src="require('@/assets/images/icons/like/smile.svg')" width="22" v-b-tooltip.hover.v-default title="Funny"></b-img></div>
                      </div>
                    </div>
                    <!-- <span class="text-primary"  v-if="comment.likes_count"
                    v-b-modal="'show-likes-' + comment.hashid">{{
                      kFormatter(comment.likes_count)
                    }}</span> -->
                  </b-link>
                  <b-modal
                  :id="'show-likes-' + comment.hashid"
                  hide-footer
                  modal-class="modal-primary modal-likes"
                  scrollable
                  title="Reactions"
                  class="text-danger"
                >
                  <b-list-group v-if="comment.likes && comment.likes.length">
                    <b-list-group-item
                      v-for="like in comment.likes"
                      :key="'user-' + like.user.hashid"
                      class="d-flex align-items-center"
                    >
                      <b-avatar :src="like.user.avatar" size="24" class="mr-1" />
                      {{ like.user.name }}
                      <div class="ml-auto" v-if="like.type == 'like'" ><b-img :src="require('@/assets/images/icons/like/like.png')" width="24" title="Like"></b-img></div>
                      <div class="ml-auto" v-if="like.type == 'clap'" ><b-img :src="require('@/assets/images/icons/like/clapping.png')" width="24" title="Celebrate"></b-img></div>
                      <div class="ml-auto" v-if="like.type == 'love'" ><b-img :src="require('@/assets/images/icons/like/love-icon.png')" width="24" title="Love"></b-img></div>
                      <div class="ml-auto" v-if="like.type == 'support'" ><b-img :src="require('@/assets/images/icons/like/hand-heart.png')" width="24" title="Support"></b-img></div>
                      <div class="ml-auto" v-if="like.type == 'idea'" ><b-img :src="require('@/assets/images/icons/like/lightbulb.png')" width="24" title="Insightful"></b-img></div>
                      <div class="ml-auto" v-if="like.type == 'smile'" ><b-img :src="require('@/assets/images/icons/like/smile.png')" width="24"  title="Funny"></b-img></div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-card-text v-else-if="comment.likes && comment.likes.length == 0">
                    <div class="item error text-center my-2 empty-data">
                      <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
                      <h4> OH NO!</h4>
                      <p class="d-block mb-1">No Records Found!</p>
                    </div>
                  </b-card-text>
                  <b-card-text v-else-if="comment.likes == null">
                    <div class="d-flex align-items-center">
                      <strong>Loading...</strong>
                      <b-spinner class="ml-auto" variant="primary" />
                    </div>
                  </b-card-text>
                </b-modal>
                        
                  </div>
                  <div class="reply-action d-flex align-items-center mt-0">
                    <b-link class="btn btn-icon text-primary rounded-circle p-50 small nav-link"  @click="addReply(comment, index)"  v-b-tooltip.hover.v-default title="Reply to Comment">
                      <!-- <feather-icon icon="CornerUpLeftIcon" size="12" v-b-tooltip.hover.v-default title="Reply to Comment" /> -->
                      Reply
                    </b-link>
                  </div>
                </b-row>
      </div>
      </div>
        <!-- Reply and Like Section -->
        <div class="reply-cmd">
                <Replies
                v-if="comment.reply_count "
                  :itemId="comment.hashid"
                  :reply_count="comment.reply_count"
                  :mentionList="mentionList"
                />
              </div>
              <div
                v-if="comment_id && comment_id == comment.hashid"
                class="replyto-cmd pb-1 my-1"
              >
              <div class="d-flex justify-content-between align-items-center">
                <div class="small">
                  Reply to <span class="font-weight-bolder">{{ reply_to }}</span>
                  <!-- <b-link class="nav-links ml-1">
                    
                  </b-link> -->
                  </div>
                  <b-link
                    class="text-primary btn btn-icon rounded-circle p-50"
                    @click="removeReply"
                  >
                    <feather-icon icon="XIcon" size="18" 
                    v-b-tooltip.hover.v-default
                    title="Cancel"/>
                  </b-link>
                </div>
              <!--/ add comments -->
              <div  v-if="comment_id && comment_id == comment.hashid" class="w-100 cmt-posts d-flex align-items-end justify-content-center">
                <div class="border w-100 p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round mr-75">
                  <CommentBox
                    placeholder="Enter Your Reply"
                    className="comment-box"
                    :id="'comment-field-' + comment.hashid"
                    :toolbar="false"
                    :mentionList="mentionList"
                  />
                </div>
                <b-button
                  variant="primary"
                  @click="addComment(comment)"
                  pill
                  class="blog-btn-block rounded-circle p-75 search-post mb-0"
                  :disabled="commentSubmitClicked"
                >
                  <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img> 
                </b-button>
              </div>
              </div>
    </div>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import QuillInput from "@/components/QuillInput.vue";
import CommentBox from "@/components/CommentBox.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
import Replies from "./Replies.vue";
import { kFormatter } from "@core/utils/filter";
import $ from "jquery";
Vue.use(VueCookies);

export default {
  name:'Replies',
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    QuillInput,
    CommentBox,
    Replies
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    reply_count: {
      type: Number,
      required: true,
      default: 0,
    },
    mentionList: {
      required: true,
    },
  },

  data() {
    return {
      isBusy: false,
      data_local: {
        channel_type: "community",
      },
      customFields: [],
      data: [],
      replies: [],
      userData: this.$cookies.get("userData"),
      editComments: [],
      show:false,
      comment_id: null,
      comment_post_index:null,
      reply_to: null,
      commentSubmitClicked:false,
    };
  },
  setup(data_local, myFiles) {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarActive(val) {
      this.data_local = {
        channel_type: "community",
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
        this.data_local.category_id = this.itemData.support_category.hashid;
      }
    },
  },
  mounted() {},
  methods: {
    kFormatter,
    editComment(comment, post_id) {
      this.commentSubmitClicked=true;
      const self = this;
      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        this.commentSubmitClicked=false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.getReplyComments(post_id);
          this.commentSubmitClicked=false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked=false;
        });
      return true;
    },
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    deleteComment(commentId, postId, itemId) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          this.getReplyComments(itemId);
        });
    },
    getReplyComments(comment_id,liked = false) {
      const self = this;
      this.isBusy = true;
      let data = {};

      data.url = "/comments/" + comment_id + "/replies";
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.replies = res.data.data;
          this.isBusy = false;
          if(!liked){
            this.show = !this.show;
          }
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    
    getCustomFields() {
      const self = this;

      let data = {};
      data.url = "/posts/create";

      self.$store
        .dispatch("app/create", data)
        .then((res) => {
          self.channel_type_select = res.data.channel_type_select;
          self.identity_type_select = res.data.identity_type_select;
          self.feedback_type_select = res.data.feedback_type_select;
        })
        .catch((error) => {
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Custom Fields Getting Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    resetFormData() {
      console.log("reset");
      // this.data_local = {
      //   priority: "medium",
      //   status: "open",
      //   get_customfields_data: [],
      //   files: [],
      // };
      // if (this.itemId) {
      //   this.data_local = this.itemData;
      //   this.data_local.category_id = this.itemData.support_category.hashid;
      // }
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("post-form"));

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }
      let data = {};
      data.url = "posts";
      // data.params = formData;
      data.params = self.data_local;

      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          // Add this post to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Post Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    formUpdate() {
      const self = this;
      const formData = new FormData(document.getElementById("post-form"));

      formData.append("status", self.data_local.status);
      formData.append("priority", self.data_local.priority);
      formData.append("category_id", self.data_local.category_id);
      formData.append("_method", "PATCH");

      formData.delete("files[]"); // data is the name of the original input file; import to delete
      const files = self.$refs.pond.getFiles();
      const keys = Object.keys(self.$refs.pond.getFiles());

      for (let i = 0; i < keys.length; i += 1) {
        formData.append(`files[${i}]`, files[i].file);
      }

      let data = {};
      data.url = "posts/" + self.data_local.hashid;
      self.data_local._method = "PATCH";
      data.params = formData;

      this.isBusy = true;
      this.$store
        .dispatch("app/update", data)
        .then((res) => {
          // Add this post to NHR
          this.isBusy = false;
          self.$refs.sidebar.hide();
          self.data_local = {
            priority: "medium",
            status: "open",
            subject: " ",
            get_customfields_data: [],
          };
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Post Added",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Post Adding Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-posts/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
          this.data_local.category_id = res.data.data.support_category.hashid;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    addReply(data, index) {
      const self = this;
      self.comment_id = data.hashid;
      self.reply_to = data.user.name;
      self.comment_post_index = index;
      document.getElementById("comment-field-" + data.hashid).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = null;
    },
    likeComment(comment, index, type) {
      if (comment.youLiked && comment.likeType == type) {
        this.$http.delete(`/comments/${comment.hashid}/like`).then((res) => {
          // this.$parent.updatePostArray(res.data.data, index);
          comment.youLiked = false;
          comment.likeType = false;
          this.$set(this.replies, index, comment);
        });
      } else {
        this.$http.post(`/comments/${comment.hashid}/like/${type}`).then((res) => {
          comment.likeType = type;
          comment.youLiked = true;
          this.$set(this.replies, index, comment);
        });
      }
    },
    deleteComment(commentId, postId, index) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          //this.$parent.updatePostArray(res.data.data, index);
          this.$forceUpdate(); 
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Comment deleted successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
        });
    },
    addComment(data) {
      this.commentSubmitClicked =true;
      const self = this;
      const comment = document.getElementById(`comment-field-${data.hashid}`).value;
      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Can't post empty comment",
            variant: "danger",
          },
        });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: data.hashid,
      };
      this.$http
        .post(`/posts/${data.post_hashid}/comments`, params)
        .then((res) => {
          self.reply_to = null;
          self.comment_id = null;
          document.getElementById(`comment-field-${data.hashid}`).value = "";
          $(".ql-editor").html("");
          this.getReplyComments(data.hashid);
          this.$forceUpdate();
          this.commentSubmitClicked = false; 
        })
        .catch((error) => {
          // Do something on error
          console.log(error);
          this.commentSubmitClicked = false;
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    getMosReactions(likes){
      var reactions = {
        'like' : 0,
        'love' : 0,
        'support' : 0,
        'clap' : 0,
        'smile' : 0,
        'idea':0
      };

      likes.forEach(like => {
        reactions[like.type] += 1;
      });
      const top3 = Object.keys(reactions)
        .sort((a, b) => reactions[b] - reactions[a])
        .slice(0, 3)
        .filter(key => reactions[key] !== 0);

      return top3;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
