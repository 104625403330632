<template>
  <div>
    <!--/ add comments -->
    <div class="w-100 cmt-posts d-flex align-items-end justify-content-center">
      <div
        class="w-100 border p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round mr-75 ml-2 my-2 main-post">
        <CommentBox placeholder="Share your thoughts..." className="comment-box" :id="'comment-field-' + index"
          :toolbar="false" :mentionList="employeeListOptions" width="100%" />
      </div>
      <b-button variant="primary" @click="addComment(post, index)"
        class="blog-btn-block mr-1 search-post rounded-circle p-75" :disabled="commentSubmitClicked">
        <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="26"></b-img>
      </b-button>
    </div>
    <div class="pb-2 px-2 bg-reply" v-if="post.latest_comments.length">
      <!-- comments -->
      <div v-for="(comment, ind) in post.latest_comments" :key="'comment-' + ind" class="reply-post mb-1 mt-50">
        <div :class="comment.user.hashid == userData.hashid ? 'cmd-main cmd-owner' : 'cmd-main' ">
          <div class="d-flex align-items-start w-100 over-cmd">
            <b-avatar target="_blank" :href="`/profile/${comment.user.hashid}`" :src="comment.user.avatar" size="30" class="mr-75 mt-0" />
            <div v-if="!editComments.includes(comment.hashid)" class="profile-user-info w-100 right-cmt mt-0">
              <div class="d-flex align-items-start justify-content-between">
                <div class="profile-user-info">
                  <h6 class="mb-0">
                    {{ comment.user.name }}
                  </h6>
                  <small style="font-size: 12px">
                  {{ comment.user.designation }}
                </small>
                </div>
                <div class="d-flex flex-column flex-lg-row align-items-lg-center">
                  <span class="small mr-50">
                    {{ comment.created_at }}
                  </span>
                  <!-- More Options Dots -->
                  <b-dropdown class="blog-dropdown" variant="link" size="sm" toggle-class="text-decoration-none p-0"
                    no-caret v-if="comment.user.hashid == userData.hashid">
                    <template v-slot:button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25 text-muted"
                        v-b-tooltip.hover.v-default title="More Options" />
                    </template>
                    <b-dropdown-item class="btn-sm p-0 text-muted" v-if="comment.user.hashid == userData.hashid" @click="
                      editComments.pop(), editComments.push(comment.hashid)
                      ">
                      <feather-icon icon="EditIcon" size="18" v-b-tooltip.hover.v-default class="mr-50"
                        title="Comment Edit" /><span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item class="btn-sm p-0 text-danger"
                      @click="deleteComment(comment.hashid, post.hashid, index)"
                      v-if="comment.user.hashid == userData.hashid">
                      <feather-icon icon="TrashIcon" size="18" v-b-tooltip.hover.v-danger class="mr-50"
                        title="Delete Comment" />
                      <span class="">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-- End More Options Dots -->
                  <!-- <div class="d-none d-lg-block">
              <b-link
                @click="like(comment.hashid, index)"
                class="text-body btn btn-icon rounded-circle p-50"
                v-b-tooltip.html.hover="
                  'Liked by ' + getNames(comment.likes)
                "
              >
                <feather-icon
                  icon="HeartIcon"
                  size="18"
                  class="mr-25"
                  :class="
                    comment.youLiked
                      ? 'profile-likes'
                      : 'profile-icon'
                  "
                />
                <span
                  v-if="comment.likes_count"
                  class="text-muted align-middle"
                  >{{ kFormatter(comment.likes_count) }}</span
                >
              </b-link>
              <b-link
                v-if="comment.user.hashid == userData.hashid"
                class="btn btn-icon text-primary rounded-circle p-50"
                @click="
                  editComments.pop(),
                    editComments.push(comment.hashid)
                "
              >
                <feather-icon icon="EditIcon" size="18" 
                v-b-tooltip.hover.v-default
                title="Comment Edit"/>
              </b-link>
              <b-link
                v-if="comment.user.hashid == userData.hashid"
                class="text-danger btn btn-icon rounded-circle p-50"
                @click="
                  deleteComment(comment.hashid, post.hashid, index)
                "
              >
                <feather-icon icon="TrashIcon" size="18" 
                v-b-tooltip.hover.v-danger
                title="Delete Comment"/>
              </b-link>
              <b-link
                class="btn btn-icon text-primary rounded-circle p-50"
                @click="addReply(comment, index)"
              >
                <feather-icon icon="CornerUpLeftIcon" size="18" 
                v-b-tooltip.hover.v-default
                title="Reply to Comment" />
              </b-link>
            </div> -->
                </div>
              </div>
              <p v-html="comment.comment" class="mb-0 mt-75" style="letter-spacing: 1px; font-size: 14px"></p>
              <div class="d-none mb-lg-0 d-lg-none">
                <b-link @click="like(comment.hashid, index)"
                  class="text-body btn btn-icon rounded-circle pl-0 pt-50 pb-50 pr-50">
                  <feather-icon icon="HeartIcon" size="18" class="mr-25"
                    :class="comment.youLiked ? 'profile-likes' : 'profile-icon'" />
                  <!-- <span v-if="comment.likes_count" class="text-muted align-middle">{{ kFormatter(comment.likes_count)
                  }}</span> -->
                </b-link>
                <b-link v-if="comment.user.hashid == userData.hashid"
                  class="btn btn-icon text-primary rounded-circle p-50"
                  @click="editComments.pop(), editComments.push(comment.hashid)">
                  <feather-icon icon="EditIcon" size="18" v-b-tooltip.hover.v-default title="Edit Comment" />
                </b-link>
                <b-link v-if="comment.user.hashid == userData.hashid" class="text-danger btn btn-icon rounded-circle p-50"
                  @click="deleteComment(comment.hashid, post.hashid, index)">
                  <feather-icon icon="TrashIcon" size="18" v-b-tooltip.hover.v-danger title="Delete Comment" />
                </b-link>
                <b-link class="btn btn-icon text-primary rounded-circle p-50" @click="addReply(comment, index)">
                  <feather-icon icon="CornerUpLeftIcon" size="18" v-b-tooltip.hover.v-default title="Repy to Comment" />
                </b-link>
              </div>

              <!-- <div>
          <b-link class="nav-link" @click="addReply(comment, index)">
            <feather-icon
              icon="CornerUpLeftIcon"
              size="18"
              class="mr-25"
            />
            Reply
          </b-link>
        </div> -->
            </div>
            <div class="w-100 cmt-posts d-flex align-items-end justify-content-centent"
              v-else="editComments.includes(comment.hashid)">
              <div
                class="border p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round w-100">
                <CommentBox placeholder="Share your thoughts..." className="w-100 comment-box"
                  :id="'comment-field-' + comment.hashid" :value="comment.comment" :toolbar="false"
                  :mentionList="employeeListOptions" />
              </div>

              <b-button variant="primary" @click="editComment(comment, post.hashid, index)" pill
                class="blog-btn-block ml-50 search-post w-auto save-button mb-0" :disabled="commentSubmitClicked">
                <strong><b>Save</b></strong>
              </b-button>
              <b-link v-if="editComments.includes(comment.hashid)"
                class="btn btn-icon rounded-circle btn-outline-danger ml-75 p-50 mb-25" @click="editComments.pop()">
                <!-- @click="editComments = []" -->
                <feather-icon icon="XIcon" size="18" v-b-tooltip.hover.v-default title="Cancel" />
              </b-link>
            </div>
          </div>
          <div class="option-reply d-flex align-items-center justify-content-between w-100 flex-row-reverse pl-3">
            <b-row class="like-button mx-0">
              <div class="action-group d-flex align-items-center my-25">
                <b-link class="d-flex align-items-center text-muted text-nowrap">
                  <div class="likes" v-if="!comment.youLiked">
                    <!-- <feather-icon
                    icon="HeartIcon"
                    class="mr-50 text-primary profile-icon"
                    size="18"
                    title="Like"
                    @click="like(comment.hashid, index, 'like')"
                  /> -->
                    <span title="Like" class="active-like" @click="like(comment.hashid, index, 'like')">Like </span>
                    <div class="emoji-reactions">
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'like')">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="18"
                          v-b-tooltip.hover.v-default title="Like"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'clap')">
                        <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                          " width="18" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'love')">
                        <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                          " width="18" v-b-tooltip.hover.v-default title="Love"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'support')">
                        <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                          " width="18" v-b-tooltip.hover.v-default title="Support"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'idea')">
                        <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                          " width="18" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'smile')">
                        <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="18"
                          v-b-tooltip.hover.v-default title="Funny"></b-img>
                      </div>
                    </div>
                  </div>
                  <div class="likes" v-if="comment.youLiked && comment.likeType">
                    <div v-if="comment.likeType == 'like'" @click="like(comment.hashid, index, 'like')">
                      <b-img :src="require('@/assets/images/icons/like/like.svg')" width="20" title="Like"
                        class="active-like"></b-img>
                    </div>
                    <div v-if="comment.likeType == 'clap'" @click="like(comment.hashid, index, 'clap')">
                      <b-img :src="require('@/assets/images/icons/like/clapping.svg')" width="20" title="Celebrate"></b-img>
                    </div>
                    <div v-if="comment.likeType == 'love'" @click="like(comment.hashid, index, 'love')">
                      <b-img :src="require('@/assets/images/icons/like/love-icon.svg')" width="20" title="Love"></b-img>
                    </div>
                    <div v-if="comment.likeType == 'support'" @click="like(comment.hashid, index, 'support')">
                      <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                        " width="20" title="Support"></b-img>
                    </div>
                    <div v-if="comment.likeType == 'idea'" @click="like(comment.hashid, index, 'idea')">
                      <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')" width="20" title="Insightful"
                        class="active-idea"></b-img>
                    </div>
                    <div v-if="comment.likeType == 'smile'" @click="like(comment.hashid, index, 'smile')">
                      <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="20" title="Funny"></b-img>
                    </div>

                    <div class="emoji-reactions">
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'like')">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="22"
                          v-b-tooltip.hover.v-default title="Like"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'clap')">
                        <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                          " width="22" v-b-tooltip.hover.v-default title="Celebrate"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'love')">
                        <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                          " width="22" v-b-tooltip.hover.v-default title="Love"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'support')">
                        <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                          " width="22" v-b-tooltip.hover.v-default title="Support"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'idea')">
                        <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                          " width="22" v-b-tooltip.hover.v-default title="Insightful"></b-img>
                      </div>
                      <div class="emoji-item py-0" @click="like(comment.hashid, index, 'smile')">
                        <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="22"
                          v-b-tooltip.hover.v-default title="Funny"></b-img>
                      </div>
                    </div>
                  </div>

                  <!-- <span class="text-primary" v-if="comment.likes_count" v-b-modal="'show-likes-' + comment.hashid">{{
                    kFormatter(comment.likes_count) }}</span> -->
                </b-link>
                <b-modal :id="'show-likes-' + comment.hashid" hide-footer modal-class="modal-primary modal-likes"
                  scrollable title="Reactions" class="text-danger">
                  <b-list-group v-if="comment.likes && comment.likes.length">
                    <b-list-group-item v-for="like in comment.likes" :key="'user-' + like.user.hashid"
                      class="d-flex align-items-center">
                      <b-avatar target="_blank" :href="`/profile/${like.user.hashid}`":src="like.user.avatar" size="24" class="mr-1" />
                      {{ like.user.name }}
                      <div class="ml-auto" v-if="like.type == 'like'">
                        <b-img :src="require('@/assets/images/icons/like/like.svg')" width="24" title="Like"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'clap'">
                        <b-img :src="require('@/assets/images/icons/like/clapping.svg')
                          " width="24" title="Celebrate"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'love'">
                        <b-img :src="require('@/assets/images/icons/like/love-icon.svg')
                          " width="24" title="Love"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'support'">
                        <b-img :src="require('@/assets/images/icons/like/hand-heart.svg')
                          " width="24" title="Support"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'idea'">
                        <b-img :src="require('@/assets/images/icons/like/lightbulb.svg')
                          " width="24" title="Insightful"></b-img>
                      </div>
                      <div class="ml-auto" v-if="like.type == 'smile'">
                        <b-img :src="require('@/assets/images/icons/like/smile.svg')" width="24" title="Funny"></b-img>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <b-card-text v-else-if="comment.likes && comment.likes.length == 0">
                    <div class="item error text-center my-2 empty-data">
                      <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50"
                        style="background-color: transparent" />
                      <h4> OH NO!</h4>
                      <p class="d-block mb-1">No Records Found!</p>
                    </div>
                  </b-card-text>
                  <b-card-text v-else-if="comment.likes == null">
                    <div class="d-flex align-items-center">
                      <strong>Loading...</strong>
                      <b-spinner class="ml-auto" variant="primary" />
                    </div>
                  </b-card-text>
                </b-modal>
                <!-- <div class="d-none d-lg-flex mobile-avatar align-items-center">                    
                    <b-avatar-group size="18" class="ml-75">
                      <div class="d-flex reaction-details"  v-for="(like, i) in getMosReactions(comment.likes)">
                        <b-img v-if="like == 'love'" :src="require('@/assets/images/icons/like/love-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'idea'" :src="require('@/assets/images/icons/like/idea-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'clap'" :src="require('@/assets/images/icons/like/clap-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'like'" :src="require('@/assets/images/icons/like/thumb-emoji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'support'" :src="require('@/assets/images/icons/like/handlove-emji.png')" width="18" height="18"/>
                        <b-img v-if="like == 'smile'" :src="require('@/assets/images/icons/like/smile-emji.png')" width="18" height="18"/>
                      </div>
                      <span class="px-25"></span>
                      <b-avatar
                        v-for="(like, i) in comment.likes"
                        v-if="i < 3"
                        :key="'avatar-' + i"
                        v-b-tooltip.hover.bottom="like.user.name"
                        class="pull-up m-0"
                        :src="like.user.avatar"
                      />
                    </b-avatar-group>
                    <b-link
                      v-if="comment.likes_count > 3"
                      class="text-primary text-nowrap ml-25"
                      v-b-modal="'show-likes-' + comment.hashid"
                    >
                      +{{ comment.likes_count - 3 }} more
                    </b-link>
                  </div> -->
              </div>
              <div class="reply-action d-flex align-items-center mt-0">
                <b-link class="btn btn-icon text-primary rounded-circle p-50 small" @click="addReply(comment, index)">
                  Reply
                </b-link>
              </div>
            </b-row>
          </div>
        </div>
        <Replies v-if="comment.reply_count" :itemId="comment.hashid" :reply_count="comment.reply_count"
          :mentionList="employeeListOptions" />

        <!-- Reply and Like Section -->
        <div class="reply-cmd">
          <div class="replyto-cmd pb-1 my-1" v-if="comment_id &&
            comment_id == comment.hashid &&
            comment_post_index == index
            ">
            <div class="d-flex justify-content-between align-items-center">
              <div class="small">
                Reply to <span class="font-weight-bolder">{{ reply_to }}</span>
                <!-- <b-link class="nav-links ml-1">
                  
                </b-link> -->
              </div>
              <b-link class="text-primary btn btn-icon rounded-circle p-50" @click="removeReply">
                <feather-icon icon="XIcon" size="18" v-b-tooltip.hover.v-default title="Cancel" />
              </b-link>
            </div>
            <!--/ add comments -->
            <div class="w-100 cmt-posts d-flex align-items-end justify-content-center">
              <div
                class="border w-100 p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round mr-75">
                <CommentBox placeholder="Enter Your Reply" className="w-100 comment-box"
                  :id="'comment-field-' + comment_id" :toolbar="false" :mentionList="employeeListOptions" />
              </div>
              <b-button variant="primary" @click="addComment(post, index)" pill
                class="blog-btn-block rounded-circle p-75 search-post mb-0" :disabled="commentSubmitClicked">
                <b-img :src="require('@/assets/images/icons/post-cmt-white.svg')" width="22"></b-img>
              </b-button>
            </div>
          </div>

        </div>
        <!-- End Reply and Like Section -->
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import QuillInput from "@/components/QuillInput.vue";
import CommentBox from "@/components/CommentBox.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
import Replies from "./Replies.vue";
import $ from "jquery";
Vue.use(VueCookies);

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    QuillInput,
    Replies,
    CommentBox,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    post: {
      required: true,
    },
    index: {
      required: true,
    },
    employeeListOptions: {
      type: Array,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userData: this.$cookies.get("userData"),
      editComments: [],
      comment_id: null,
      comment_post_index: null,
      reply_to: null,
      commentSubmitClicked: false,
    };
  },
  computed: {},
  mounted() { },
  methods: {
    kFormatter,
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },

    addReply(data, index) {
      const self = this;
      self.comment_id = data.hashid;
      self.reply_to = data.user.name;
      self.comment_post_index = index;
      document.getElementById("comment-field-" + index).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = null;
    },
    like(comment_id, index, type) {
      const self = this;
      let data = {};
      data.url = "comments/" + comment_id + "/like/" + type;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.$parent.updatePostArray(res.data.data, index);
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "liking failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    deleteComment(commentId, postId, index) {
      this.$http
        .delete(`/posts/${postId}/comments/${commentId}`)
        .then((res) => {
          this.$parent.updatePostArray(res.data.data, index);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Comment deleted successfully",
              icon: "BellIcon",
              variant: "success",
            },
          });
        });
    },
    addComment(data, index) {
  
      this.commentSubmitClicked = true;
      const self = this;
      var comment = document.getElementById(`comment-field-${index}`).value;
      if (self.comment_id) {
        var comment = document.getElementById(
          `comment-field-${self.comment_id}`
        ).value;
      }
      if (comment === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Comment adding Failed",
            icon: "BellIcon",
            text: "Can't post empty comment",
            variant: "danger",
          },
        });
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: comment,
        parent_comment_id: self.comment_id,
      };
      this.$http
        .post(`/posts/${data.hashid}/comments`, params)
        .then((res) => {
          self.reply_to = null;
          self.comment_id = null;
          this.$parent.updatePostArray(res.data.data, index);
          document.getElementById(`comment-field-${index}`).value = "";
          $(".ql-editor").html("");
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          // Do something on error
          console.log(error);
          this.commentSubmitClicked = false;
          if (error.response.status === 422) {
            if (typeof error.response.data.errors !== "undefined") {
              const keys = Object.keys(error.response.data.errors);
              const values = Object.values(error.response.data.errors);
              for (let i = 0; i <= keys.length; i += 1) {
                if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Comment adding Failed",
                      icon: "BellIcon",
                      text: values[0][0],
                      variant: "danger",
                    },
                  });
                }
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Comment adding Failed",
                icon: "BellIcon",
                text: "Oops! Something Went Wrong",
                variant: "danger",
              },
            });
          }
        });
      return true;
    },
    editComment(comment, post_id, index) {
      this.commentSubmitClicked = true;
      const self = this;
      const message = document.getElementById(
        `comment-field-${comment.hashid}`
      ).value;
      if (message === "") {
        this.commentSubmitClicked = false;
        return false;
      }

      let params = {};
      params = {
        comment: message,
      };

      this.$http
        .patch(`/comments/${comment.hashid}`, params)
        .then((res) => {
          this.editComments.pop();
          this.$parent.updatePostArray(res.data.data, index);
          this.commentSubmitClicked = false;
        })
        .catch((error) => {
          console.log(error);
          this.commentSubmitClicked = false;
        });
      return true;
    },
    getMosReactions(likes) {
      var reactions = {
        like: 0,
        love: 0,
        support: 0,
        clap: 0,
        smile: 0,
        idea: 0,
      };

      likes.forEach((like) => {
        reactions[like.type] += 1;
      });
      const top3 = Object.keys(reactions)
        .sort((a, b) => reactions[b] - reactions[a])
        .slice(0, 3)
        .filter((key) => reactions[key] !== 0);

      return top3;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vue__time-picker {
  width: 6em !important;
}

.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}

.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}

.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}

select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.flatpickr-input {
  background: whitesmoke !important;
}
</style>
