var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.comments),function(comment,index){return _c('div',{key:'comment-' + index,staticClass:"nest-reply my-75"},[_c('div',{staticClass:"nested-main"},[_c('div',{staticClass:"d-flex align-items-start w-100 over-cmd px-2 pt-2"},[_c('div',{staticClass:"profile-user-info w-100 right-cmt"},[_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('div',{staticClass:"profile-user-info"},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(comment.username))])]),_c('span',{staticClass:"small mr-50"},[_vm._v(_vm._s(_vm._f("formatDate")(comment.created_at)))])]),_c('div',{staticClass:"mt-75 blog-description"},[_c('p',{staticClass:"child-comment-content mb-0",domProps:{"innerHTML":_vm._s(comment.comment)}})])])]),_c('div',{staticClass:"option-reply nested-replycount d-flex align-items-center justify-content-between w-100 pl-3"},[_c('div',{staticClass:"cmtnest-reply",on:{"click":function($event){return _vm.viewReply(index)}}},[_c('p',{staticClass:"nav-link reply-count cursor-pointer mb-0"},[(
                                _vm.openReplyIndex !== index &&
                                comment.reply_count
                            )?_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"14"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.openReplyIndex === index ? "Hide Replies" : comment.reply_count ? ((comment.reply_count) + " Replies") : "")+" ")],1)]),_c('div',{staticClass:"row like-button mx-0"},[_c('div',{staticClass:"action-group d-flex align-items-center my-25"},[_c('b-link',{staticClass:"btn btn-icon text-primary rounded-circle p-50 small",on:{"click":function($event){return _vm.addReply(index)}}},[_vm._v(" Reply ")])],1)])])]),(_vm.replyIndex === index)?_c('div',{staticClass:"w-100 cmt-posts d-flex align-items-end justify-content-center"},[_c('div',{staticClass:"border w-100 p-751 d-flex mb-0 flex-row align-items-end justify-content-around commentBox round mr-75"},[_c('CommentBox',{attrs:{"placeholder":"Enter Your Reply","className":"w-100 comment-box","id":'comment-field-' + comment.hashid,"toolbar":false}})],1),_c('b-button',{staticClass:"blog-btn-block rounded-circle p-75 search-post mb-0",attrs:{"variant":"primary","pill":"","disabled":_vm.commentSubmitClicked},on:{"click":function($event){return _vm.addComments(comment)}}},[_c('b-img',{attrs:{"src":require('@/assets/images/icons/post-cmt-white.svg'),"width":"22"}})],1),_c('div',{staticClass:"replyto-cmd pb-1 my-1"},[_c('b-link',{staticClass:"text-primary btn btn-icon rounded-circle p-50",on:{"click":_vm.closeReplyBox}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-default",modifiers:{"hover":true,"v-default":true}}],attrs:{"icon":"XIcon","size":"18","title":"Cancel"}})],1)],1)],1):_vm._e(),(
                _vm.openReplyIndex === index &&
                comment.replies &&
                comment.replies.length > 0
            )?_c('NestedComments',{attrs:{"comments":comment.replies,"postData":_vm.postData}}):_vm._e()],1)}),_c('b-modal',{attrs:{"id":"modal-login","cancel-variant":"outline-secondary","centered":"","title":"Username","hide-footer":""},model:{value:(_vm.showUsernameModal),callback:function ($$v) {_vm.showUsernameModal=$$v},expression:"showUsernameModal"}},[_c('b-form',[_c('b-form-group',[_c('label',{attrs:{"for":"username"}},[_vm._v("Username:")]),_c('b-form-input',{attrs:{"id":"username","type":"text","placeholder":"Enter username"},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.showUsernameModal = false}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submitUsername}},[_vm._v("Continue")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }